import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'
import './layout.css'

const Layout = props => {
  const data = useStaticQuery(graphql`
    query data {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="layout-wrapper">
      {props.headerState && <Header siteTitle={data.site.siteMetadata.title} />}
      <main>{props.children}</main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
