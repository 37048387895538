import PropTypes from "prop-types"
import React from "react"
import headerStyles from "./headerStyles.module.css"
import WhiteLogo from '../images/jonatan_velazquez_logo_white.svg'

const Header = ({ siteTitle }) => (
  <header>
    <div id={headerStyles.headerContentWrapper}>
      <div id={headerStyles.headingWrapper}>
        <img id={headerStyles.headerLogo} src={WhiteLogo} alt="page logo" />
        <h1>{siteTitle}</h1>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
